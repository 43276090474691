import Button from 'components/atoms/button/Button'
import Card from 'components/atoms/card/Card'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router'
import { useHistory } from 'react-router-dom'
import { CardBody, CardFooter, CardTitle } from 'reactstrap'
import { meetingService } from 'services/meetingService'
import { Meeting, MeetingDashboard, MeetingPreview, MeetingStatus } from 'types/Meeting'
import { PATHS } from 'utils/constants/routes/RoutePaths'
import { useInstance } from 'utils/hooks/usePermissions'

import { INVITATION_STATUS } from '../../../../utils/constants/Status'

import { InvitationStatusType } from '../../../../types/InvitationStatus'

import { BASE_PATH } from '../../../../api/api'
import DateCard from './DateCard'
import './MeetingCard.scss'
import MeetingPointsOverview from './MeetingPointsOverview'
import MeetingStatutes from './MeetingStatutes'

export interface MeetingCardProps {
  status?: InvitationStatusType
  meeting?: Meeting | MeetingDashboard | MeetingPreview
  clickable?: boolean
  subtitled?: boolean
  date?: Date
  showDownloadConvocation?: boolean
  dashboardMode?: boolean
}

const MeetingCard: FC<MeetingCardProps> = (props) => {
  const {
    status,
    meeting,
    showDownloadConvocation = false,
    clickable = false,
    dashboardMode = false,
    subtitled = false,
  } = props
  const { t } = useTranslation()
  const history = useHistory()

  if (!meeting) return null

  const onCardClick = () => {
    clickable && history.push(generatePath(PATHS.MEETINGS.MEETING_DETAILS, { id: meeting.id }))
  }

  const instance = useInstance(meeting.instance.id)
  const received = !!status && INVITATION_STATUS[status] !== INVITATION_STATUS.NOT_SENT
  const postponed = meeting.status === MeetingStatus.CANCELED
  const meetingAddress = meetingService.formatAddress(meeting.meeting_address)
  const meetingAddressFormatted =
    meetingAddress.length > 70 && dashboardMode ? meetingAddress.substring(0, 70) + '\u2026' : meetingAddress

  return (
    <Card
      className='MeetingCard'
      onClick={onCardClick}
      clickable={clickable}
      boxShadow={dashboardMode ? 'none' : 'dark-medium-md'}
      color={dashboardMode ? 'mediumgrey' : 'white'}
    >
      <CardBody>
        {meeting.start_date && <DateCard date={new Date(meeting.start_date)} className='float-right' />}
        <CardTitle tag='h3'>{instance?.long_name || ''}</CardTitle>
        {subtitled && instance?.association && <p className='font-weight-lighter'>{instance.association.name}</p>}
        {postponed && (
          <CardTitle tag='h4' className='text-secondary mt-3'>
            {t('meeting.postponed')}
          </CardTitle>
        )}

        <MeetingPointsOverview
          strs={
            [
              meetingService.formatTimeSchedule(meeting.start_time, meeting.end_time),
              meetingAddressFormatted,
              !dashboardMode ? meeting.online_meeting_url : null,
            ].filter((str) => str && str.trim()) as string[] // après le filter on est obligé d'avoir une string list
          }
        />
      </CardBody>
      {meeting.instance.id != 1 && (
        <CardFooter className={status && received ? 'cancel-margin' : ''}>
          {status && received && <MeetingStatutes activeStatus={status} />}
          {showDownloadConvocation && meetingService.isMeeting(meeting) && meeting?.convocation_file && (
            <a download href={BASE_PATH + meeting?.convocation_file?.url}>
              <Button
                label={t('common.downloadConvoc')}
                color='primary'
                className='d-block w-auto order-3 mx-auto my-auto'
              />
            </a>
          )}
        </CardFooter>
      )}
    </Card>
  )
}

export default MeetingCard
